<script lang="ts">
	import Icon from "./Icon.svelte";
	import success from "../../../core/assets/icons/check.svg?raw";
	import error from "../../../core/assets/icons/error.svg?raw";
	import info from "../../../core/assets/icons/info.svg?raw";
	import warning from "../../../core/assets/icons/warning.svg?raw";

	type MessageVariant = "success" | "error" | "info" | "warning" | "express" | "white";

	let messageClass = "";
	export { messageClass as class };
	export let variant: MessageVariant = "error";
	export let iconClass = "";

	const messageVariants: Record<MessageVariant, string> = {
		success: "bg-tertiary-50 font-semibold",
		error: "bg-primary-100 border-primary text-primary font-semibold",
		info: "bg-themeBlue/5 text-secondary",
		warning: "bg-orange-100 font-semibold",
		express: "bg-express-100",
		white: "bg-white",
	};

	const messageIconVariants: Record<MessageVariant, string> = {
		success: "text-tertiary h-[.8rem] [&>svg]:mt-[.1rem]",
		error: "text-primary h-4 [&>svg]:mt-[.15rem]",
		info: "text-themeBlue h-4 [&>svg]:mt-[.15rem]",
		warning: "text-orange-400 h-[.8rem] [&>svg]:mt-[.1rem]",
		express: "text-express h-[.8rem] [&>svg]:mt-[.1rem]",
		white: "text-black",
	};

	const iconMap: Record<MessageVariant, string | undefined> = {
		success,
		error,
		info,
		warning,
		express: undefined,
		white: undefined,
	};
	const icon = iconMap[variant];
</script>

<div
	role="alert"
	class="mt-1 gap-1 text-wrap rounded-lg p-4 text-xs leading-[1.1] text-black {messageVariants[variant]} {messageClass}"
>
	{#if icon}
		<Icon {icon} class="float-left mr-1 {iconClass} {messageIconVariants[variant]}" />
	{/if}
	<slot />
</div>
